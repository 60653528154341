/**
 * This file was automatically generated by @cosmwasm/ts-codegen@0.35.3.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { selectorFamily } from 'recoil'

import { WithChainId } from '@dao-dao/types'

import { NeutronVaultClient } from '../../../contracts/NeutronVault'
import { signingCosmWasmClientAtom } from '../../atoms'

export type ExecuteClientParams = WithChainId<{
  contractAddress: string
  sender: string
}>

export const executeClient = selectorFamily<
  NeutronVaultClient | undefined,
  ExecuteClientParams
>({
  key: 'neutronVaultExecuteClient',
  get:
    ({ chainId, contractAddress, sender }) =>
    ({ get }) => {
      const client = get(signingCosmWasmClientAtom({ chainId }))
      if (!client) return

      return new NeutronVaultClient(client, sender, contractAddress)
    },
  dangerouslyAllowMutability: true,
})
