/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  AdminResponse,
  Auth,
  HooksResponse,
  MemberListResponse,
  MemberResponse,
  TotalWeightResponse,
} from '@dao-dao/types/contracts/SecretCw4Group'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { SecretCw4GroupQueryClient } from '../../../contracts/SecretCw4Group'

export const secretCw4GroupQueryKeys = {
  contract: [
    {
      contract: 'secretCw4Group',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...secretCw4GroupQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  admin: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretCw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'admin',
        args,
      },
    ] as const,
  totalWeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretCw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_weight',
        args,
      },
    ] as const,
  listMembers: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretCw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_members',
        args,
      },
    ] as const,
  member: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretCw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'member',
        args,
      },
    ] as const,
  hooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretCw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'hooks',
        args,
      },
    ] as const,
}
export const secretCw4GroupQueries = {
  admin: <TData = AdminResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretCw4GroupAdminQuery<TData>): UseQueryOptions<
    AdminResponse,
    Error,
    TData
  > => ({
    queryKey: secretCw4GroupQueryKeys.admin(chainId, contractAddress),
    queryFn: async () => {
      return new SecretCw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).admin()
    },
    ...options,
  }),
  totalWeight: <TData = TotalWeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretCw4GroupTotalWeightQuery<TData>): UseQueryOptions<
    TotalWeightResponse,
    Error,
    TData
  > => ({
    queryKey: secretCw4GroupQueryKeys.totalWeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new SecretCw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalWeight({
        atHeight: args.atHeight,
      })
    },
    ...options,
  }),
  listMembers: <TData = MemberListResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretCw4GroupListMembersQuery<TData>): UseQueryOptions<
    MemberListResponse,
    Error,
    TData
  > => ({
    queryKey: secretCw4GroupQueryKeys.listMembers(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new SecretCw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listMembers({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  member: <TData = MemberResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretCw4GroupMemberQuery<TData>): UseQueryOptions<
    MemberResponse,
    Error,
    TData
  > => ({
    queryKey: secretCw4GroupQueryKeys.member(chainId, contractAddress, args),
    queryFn: async () => {
      return new SecretCw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).member({
        atHeight: args.atHeight,
        auth: args.auth,
      })
    },
    ...options,
  }),
  hooks: <TData = HooksResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretCw4GroupHooksQuery<TData>): UseQueryOptions<
    HooksResponse,
    Error,
    TData
  > => ({
    queryKey: secretCw4GroupQueryKeys.hooks(chainId, contractAddress),
    queryFn: async () => {
      return new SecretCw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).hooks()
    },
    ...options,
  }),
}
export interface SecretCw4GroupReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface SecretCw4GroupHooksQuery<TData>
  extends SecretCw4GroupReactQuery<HooksResponse, TData> {}
export interface SecretCw4GroupMemberQuery<TData>
  extends SecretCw4GroupReactQuery<MemberResponse, TData> {
  args: {
    atHeight?: number
    auth: Auth
  }
}
export interface SecretCw4GroupListMembersQuery<TData>
  extends SecretCw4GroupReactQuery<MemberListResponse, TData> {
  args: {
    limit?: number
    startAfter?: string
  }
}
export interface SecretCw4GroupTotalWeightQuery<TData>
  extends SecretCw4GroupReactQuery<TotalWeightResponse, TData> {
  args: {
    atHeight?: number
  }
}
export interface SecretCw4GroupAdminQuery<TData>
  extends SecretCw4GroupReactQuery<AdminResponse, TData> {}
