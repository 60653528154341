/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { QueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  AllAccountsResponse,
  AllAllowancesResponse,
  AllSpenderAllowancesResponse,
  AllowanceResponse,
  BalanceResponse,
  DownloadLogoResponse,
  MarketingInfoResponse,
  MinterResponse,
  TokenInfoResponse,
} from '@dao-dao/types/contracts/Cw20Base'
import { cosmWasmClientRouter } from '@dao-dao/utils'

import { Cw20BaseQueryClient } from '../../../contracts/Cw20Base'
import { indexerQueries } from '../indexer'

export const cw20BaseQueryKeys = {
  contract: [
    {
      contract: 'cw20Base',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...cw20BaseQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  balance: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'balance',
        args,
      },
    ] as const,
  tokenInfo: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'token_info',
        args,
      },
    ] as const,
  minter: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'minter',
        args,
      },
    ] as const,
  allowance: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'allowance',
        args,
      },
    ] as const,
  allAllowances: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'all_allowances',
        args,
      },
    ] as const,
  allSpenderAllowances: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'all_spender_allowances',
        args,
      },
    ] as const,
  allAccounts: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'all_accounts',
        args,
      },
    ] as const,
  marketingInfo: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'marketing_info',
        args,
      },
    ] as const,
  downloadLogo: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20BaseQueryKeys.address(chainId, contractAddress)[0],
        method: 'download_logo',
        args,
      },
    ] as const,
}
export const cw20BaseQueries = {
  balance: <TData = BalanceResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, args, options }: Cw20BaseBalanceQuery<TData>
  ): UseQueryOptions<BalanceResponse, Error, TData> => ({
    queryKey: cw20BaseQueryKeys.balance(chainId, contractAddress, args),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer
        return {
          balance: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20/balance',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to direct contract query
      return new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).balance({
        address: args.address,
      })
    },
    ...options,
  }),
  tokenInfo: <TData = TokenInfoResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw20BaseTokenInfoQuery<TData>
  ): UseQueryOptions<TokenInfoResponse, Error, TData> => ({
    queryKey: cw20BaseQueryKeys.tokenInfo(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw20/tokenInfo',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to direct contract query
      return new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).tokenInfo()
    },
    ...options,
  }),
  minter: <TData = MinterResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw20BaseMinterQuery<TData>
  ): UseQueryOptions<MinterResponse, Error, TData> => ({
    queryKey: cw20BaseQueryKeys.minter(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw20/minter',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to direct contract query
      return new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).minter()
    },
    ...options,
  }),
  allowance: <TData = AllowanceResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, args, options }: Cw20BaseAllowanceQuery<TData>
  ): UseQueryOptions<AllowanceResponse, Error, TData> => ({
    queryKey: cw20BaseQueryKeys.allowance(chainId, contractAddress, args),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw20/allowance',
            args,
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to direct contract query
      return new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).allowance({
        owner: args.owner,
        spender: args.spender,
      })
    },
    ...options,
  }),
  allAllowances: <TData = AllAllowancesResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: Cw20BaseAllAllowancesQuery<TData>
  ): UseQueryOptions<AllAllowancesResponse, Error, TData> => ({
    queryKey: cw20BaseQueryKeys.allAllowances(chainId, contractAddress, args),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer
        return {
          allowances: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20/ownerAllowances',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to direct contract query
      return new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).allAllowances({
        limit: args.limit,
        owner: args.owner,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  allSpenderAllowances: <TData = AllSpenderAllowancesResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: Cw20BaseAllSpenderAllowancesQuery<TData>): UseQueryOptions<
    AllSpenderAllowancesResponse,
    Error,
    TData
  > => ({
    queryKey: cw20BaseQueryKeys.allSpenderAllowances(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).allSpenderAllowances({
        limit: args.limit,
        spender: args.spender,
        startAfter: args.startAfter,
      }),
    ...options,
  }),
  allAccounts: <TData = AllAccountsResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, args, options }: Cw20BaseAllAccountsQuery<TData>
  ): UseQueryOptions<AllAccountsResponse, Error, TData> => ({
    queryKey: cw20BaseQueryKeys.allAccounts(chainId, contractAddress, args),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer
        return {
          accounts: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20/allAccounts',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to direct contract query
      return new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).allAccounts({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  marketingInfo: <TData = MarketingInfoResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw20BaseMarketingInfoQuery<TData>
  ): UseQueryOptions<MarketingInfoResponse, Error, TData> => ({
    queryKey: cw20BaseQueryKeys.marketingInfo(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw20/marketingInfo',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to direct contract query
      return new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).marketingInfo()
    },
    ...options,
  }),
  downloadLogo: <TData = DownloadLogoResponse>({
    chainId,
    contractAddress,
    options,
  }: Cw20BaseDownloadLogoQuery<TData>): UseQueryOptions<
    DownloadLogoResponse,
    Error,
    TData
  > => ({
    queryKey: cw20BaseQueryKeys.downloadLogo(chainId, contractAddress),
    queryFn: async () =>
      new Cw20BaseQueryClient(
        await cosmWasmClientRouter.connect(chainId),
        contractAddress
      ).downloadLogo(),
    ...options,
  }),
}
export interface Cw20BaseReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface Cw20BaseDownloadLogoQuery<TData>
  extends Cw20BaseReactQuery<DownloadLogoResponse, TData> {}
export interface Cw20BaseMarketingInfoQuery<TData>
  extends Cw20BaseReactQuery<MarketingInfoResponse, TData> {}
export interface Cw20BaseAllAccountsQuery<TData>
  extends Cw20BaseReactQuery<AllAccountsResponse, TData> {
  args: {
    limit?: number
    startAfter?: string
  }
}
export interface Cw20BaseAllSpenderAllowancesQuery<TData>
  extends Cw20BaseReactQuery<AllSpenderAllowancesResponse, TData> {
  args: {
    limit?: number
    spender: string
    startAfter?: string
  }
}
export interface Cw20BaseAllAllowancesQuery<TData>
  extends Cw20BaseReactQuery<AllAllowancesResponse, TData> {
  args: {
    limit?: number
    owner: string
    startAfter?: string
  }
}
export interface Cw20BaseAllowanceQuery<TData>
  extends Cw20BaseReactQuery<AllowanceResponse, TData> {
  args: {
    owner: string
    spender: string
  }
}
export interface Cw20BaseMinterQuery<TData>
  extends Cw20BaseReactQuery<MinterResponse, TData> {}
export interface Cw20BaseTokenInfoQuery<TData>
  extends Cw20BaseReactQuery<TokenInfoResponse, TData> {}
export interface Cw20BaseBalanceQuery<TData>
  extends Cw20BaseReactQuery<BalanceResponse, TData> {
  args: {
    address: string
  }
}
