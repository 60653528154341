/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { QueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  ConfigResponse,
  InfoResponse,
  ListProposalsResponse,
  ListVotesResponse,
  ProposalCountResponse,
  ProposalHooksResponse,
  ProposalResponse,
  ReverseProposalsResponse,
  VoteHooksResponse,
  VoteResponse,
} from '@dao-dao/types/contracts/CwProposalSingle.v1'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { CwProposalSingleV1QueryClient } from '../../../contracts/CwProposalSingle.v1'
import { contractQueries } from '../contract'
import { indexerQueries } from '../indexer'

export const cwProposalSingleV1QueryKeys = {
  contract: [
    {
      contract: 'cwProposalSingleV1',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  config: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'config',
        args,
      },
    ] as const,
  proposal: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'proposal',
        args,
      },
    ] as const,
  listProposals: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'list_proposals',
        args,
      },
    ] as const,
  reverseProposals: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'reverse_proposals',
        args,
      },
    ] as const,
  proposalCount: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'proposal_count',
        args,
      },
    ] as const,
  vote: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'vote',
        args,
      },
    ] as const,
  listVotes: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'list_votes',
        args,
      },
    ] as const,
  proposalHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'proposal_hooks',
        args,
      },
    ] as const,
  voteHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'vote_hooks',
        args,
      },
    ] as const,
  info: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cwProposalSingleV1QueryKeys.address(chainId, contractAddress)[0],
        method: 'info',
        args,
      },
    ] as const,
}
export const cwProposalSingleV1Queries = {
  config: <TData = ConfigResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: CwProposalSingleV1ConfigQuery<TData>
  ): UseQueryOptions<ConfigResponse, Error, TData> => ({
    queryKey: cwProposalSingleV1QueryKeys.config(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoProposalSingle/config',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).config()
    },
    ...options,
  }),
  proposal: <TData = ProposalResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: CwProposalSingleV1ProposalQuery<TData>
  ): UseQueryOptions<ProposalResponse, Error, TData> => ({
    queryKey: cwProposalSingleV1QueryKeys.proposal(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoProposalSingle/proposal',
            args: { id: args.proposalId },
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposal({
        proposalId: args.proposalId,
      })
    },
    ...options,
  }),
  listProposals: <TData = ListProposalsResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: CwProposalSingleV1ListProposalsQuery<TData>
  ): UseQueryOptions<ListProposalsResponse, Error, TData> => ({
    queryKey: cwProposalSingleV1QueryKeys.listProposals(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          proposals: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoProposalSingle/listProposals',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listProposals({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  reverseProposals: <TData = ReverseProposalsResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: CwProposalSingleV1ReverseProposalsQuery<TData>
  ): UseQueryOptions<ReverseProposalsResponse, Error, TData> => ({
    queryKey: cwProposalSingleV1QueryKeys.reverseProposals(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          proposals: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoProposalSingle/reverseProposals',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).reverseProposals({
        limit: args.limit,
        startBefore: args.startBefore,
      })
    },
    ...options,
  }),
  proposalCount: <TData = ProposalCountResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options,
    }: CwProposalSingleV1ProposalCountQuery<TData>
  ): UseQueryOptions<ProposalCountResponse, Error, TData> => ({
    queryKey: cwProposalSingleV1QueryKeys.proposalCount(
      chainId,
      contractAddress
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoProposalSingle/proposalCount',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      try {
        return new CwProposalSingleV1QueryClient(
          await getCosmWasmClientForChainId(chainId),
          contractAddress
        ).proposalCount()
      } catch {
        // V1 contract throws error if no proposals have been made, so return 0.
        return 0
      }
    },
    ...options,
  }),
  vote: <TData = VoteResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: CwProposalSingleV1VoteQuery<TData>
  ): UseQueryOptions<VoteResponse, Error, TData> => ({
    queryKey: cwProposalSingleV1QueryKeys.vote(chainId, contractAddress, args),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          vote: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoProposalSingle/vote',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getVote({
        proposalId: args.proposalId,
        voter: args.voter,
      })
    },
    ...options,
  }),
  listVotes: <TData = ListVotesResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: CwProposalSingleV1ListVotesQuery<TData>
  ): UseQueryOptions<ListVotesResponse, Error, TData> => ({
    queryKey: cwProposalSingleV1QueryKeys.listVotes(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          votes: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoProposalSingle/listVotes',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listVotes({
        limit: args.limit,
        proposalId: args.proposalId,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  proposalHooks: <TData = ProposalHooksResponse>({
    chainId,
    contractAddress,
    options,
  }: CwProposalSingleV1ProposalHooksQuery<TData>): UseQueryOptions<
    ProposalHooksResponse,
    Error,
    TData
  > => ({
    queryKey: cwProposalSingleV1QueryKeys.proposalHooks(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposalHooks(),
    ...options,
  }),
  voteHooks: <TData = VoteHooksResponse>({
    chainId,
    contractAddress,
    options,
  }: CwProposalSingleV1VoteHooksQuery<TData>): UseQueryOptions<
    VoteHooksResponse,
    Error,
    TData
  > => ({
    queryKey: cwProposalSingleV1QueryKeys.voteHooks(chainId, contractAddress),
    queryFn: async () =>
      new CwProposalSingleV1QueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).voteHooks(),
    ...options,
  }),
  info: contractQueries.info,
}
export interface CwProposalSingleV1ReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface CwProposalSingleV1InfoQuery<TData>
  extends CwProposalSingleV1ReactQuery<InfoResponse, TData> {}
export interface CwProposalSingleV1VoteHooksQuery<TData>
  extends CwProposalSingleV1ReactQuery<VoteHooksResponse, TData> {}
export interface CwProposalSingleV1ProposalHooksQuery<TData>
  extends CwProposalSingleV1ReactQuery<ProposalHooksResponse, TData> {}
export interface CwProposalSingleV1ListVotesQuery<TData>
  extends CwProposalSingleV1ReactQuery<ListVotesResponse, TData> {
  args: {
    limit?: number
    proposalId: number
    startAfter?: string
  }
}
export interface CwProposalSingleV1VoteQuery<TData>
  extends CwProposalSingleV1ReactQuery<VoteResponse, TData> {
  args: {
    proposalId: number
    voter: string
  }
}
export interface CwProposalSingleV1ProposalCountQuery<TData>
  extends CwProposalSingleV1ReactQuery<ProposalCountResponse, TData> {}
export interface CwProposalSingleV1ReverseProposalsQuery<TData>
  extends CwProposalSingleV1ReactQuery<ReverseProposalsResponse, TData> {
  args: {
    limit?: number
    startBefore?: number
  }
}
export interface CwProposalSingleV1ListProposalsQuery<TData>
  extends CwProposalSingleV1ReactQuery<ListProposalsResponse, TData> {
  args: {
    limit?: number
    startAfter?: number
  }
}
export interface CwProposalSingleV1ProposalQuery<TData>
  extends CwProposalSingleV1ReactQuery<ProposalResponse, TData> {
  args: {
    proposalId: number
  }
}
export interface CwProposalSingleV1ConfigQuery<TData>
  extends CwProposalSingleV1ReactQuery<ConfigResponse, TData> {}
