/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { QueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  Config,
  NullableString,
  ProposalListResponse,
  SingleChoiceProposal,
} from '@dao-dao/types/contracts/NeutronCwdSubdaoTimelockSingle'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { NeutronCwdSubdaoTimelockSingleQueryClient } from '../../../contracts/NeutronCwdSubdaoTimelockSingle'
import { indexerQueries } from '../indexer'

export const neutronCwdSubdaoTimelockSingleQueryKeys = {
  contract: [
    {
      contract: 'neutronCwdSubdaoTimelockSingle',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...neutronCwdSubdaoTimelockSingleQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  config: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronCwdSubdaoTimelockSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'config',
        args,
      },
    ] as const,
  proposal: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronCwdSubdaoTimelockSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal',
        args,
      },
    ] as const,
  listProposals: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronCwdSubdaoTimelockSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'list_proposals',
        args,
      },
    ] as const,
  proposalExecutionError: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronCwdSubdaoTimelockSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal_execution_error',
        args,
      },
    ] as const,
}
export const neutronCwdSubdaoTimelockSingleQueries = {
  config: <TData = Config>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options,
    }: NeutronCwdSubdaoTimelockSingleConfigQuery<TData>
  ): UseQueryOptions<Config, Error, TData> => ({
    queryKey: neutronCwdSubdaoTimelockSingleQueryKeys.config(
      chainId,
      contractAddress
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'neutron/cwdSubdaoTimelockSingle/config',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new NeutronCwdSubdaoTimelockSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).config()
    },
    ...options,
  }),
  proposal: <TData = SingleChoiceProposal>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: NeutronCwdSubdaoTimelockSingleProposalQuery<TData>
  ): UseQueryOptions<SingleChoiceProposal, Error, TData> => ({
    queryKey: neutronCwdSubdaoTimelockSingleQueryKeys.proposal(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'neutron/cwdSubdaoTimelockSingle/proposal',
            args,
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new NeutronCwdSubdaoTimelockSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposal({
        proposalId: args.proposalId,
      })
    },
    ...options,
  }),
  listProposals: <TData = ProposalListResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: NeutronCwdSubdaoTimelockSingleListProposalsQuery<TData>
  ): UseQueryOptions<ProposalListResponse, Error, TData> => ({
    queryKey: neutronCwdSubdaoTimelockSingleQueryKeys.listProposals(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          proposals: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'neutron/cwdSubdaoTimelockSingle/listProposals',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new NeutronCwdSubdaoTimelockSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listProposals({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  proposalExecutionError: <TData = NullableString>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: NeutronCwdSubdaoTimelockSingleProposalExecutionErrorQuery<TData>
  ): UseQueryOptions<NullableString, Error, TData> => ({
    queryKey: neutronCwdSubdaoTimelockSingleQueryKeys.proposalExecutionError(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'neutron/cwdSubdaoTimelockSingle/proposalExecutionError',
            args,
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new NeutronCwdSubdaoTimelockSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposalExecutionError({
        proposalId: args.proposalId,
      })
    },
    ...options,
  }),
}
export interface NeutronCwdSubdaoTimelockSingleReactQuery<
  TResponse,
  TData = TResponse
> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface NeutronCwdSubdaoTimelockSingleProposalExecutionErrorQuery<
  TData
> extends NeutronCwdSubdaoTimelockSingleReactQuery<NullableString, TData> {
  args: {
    proposalId: number
  }
}
export interface NeutronCwdSubdaoTimelockSingleListProposalsQuery<TData>
  extends NeutronCwdSubdaoTimelockSingleReactQuery<
    ProposalListResponse,
    TData
  > {
  args: {
    limit?: number
    startAfter?: number
  }
}
export interface NeutronCwdSubdaoTimelockSingleProposalQuery<TData>
  extends NeutronCwdSubdaoTimelockSingleReactQuery<
    SingleChoiceProposal,
    TData
  > {
  args: {
    proposalId: number
  }
}
export interface NeutronCwdSubdaoTimelockSingleConfigQuery<TData>
  extends NeutronCwdSubdaoTimelockSingleReactQuery<Config, TData> {}
