/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  AnyContractInfo,
  Binary,
  Config,
  DepositInfoResponse,
  Empty,
  HooksResponse,
} from '@dao-dao/types/contracts/SecretDaoPreProposeSingle'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { SecretDaoPreProposeSingleQueryClient } from '../../../contracts/SecretDaoPreProposeSingle'
import { contractQueries } from '../contract'

export const secretDaoPreProposeSingleQueryKeys = {
  contract: [
    {
      contract: 'secretDaoPreProposeSingle',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...secretDaoPreProposeSingleQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  proposalModule: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoPreProposeSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal_module',
        args,
      },
    ] as const,
  dao: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoPreProposeSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'dao',
        args,
      },
    ] as const,
  config: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoPreProposeSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'config',
        args,
      },
    ] as const,
  depositInfo: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoPreProposeSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'deposit_info',
        args,
      },
    ] as const,
  proposalSubmittedHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoPreProposeSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal_submitted_hooks',
        args,
      },
    ] as const,
  queryExtension: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoPreProposeSingleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'query_extension',
        args,
      },
    ] as const,
}
export const secretDaoPreProposeSingleQueries = {
  proposalModule: <TData = AnyContractInfo>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoPreProposeSingleProposalModuleQuery<TData>): UseQueryOptions<
    AnyContractInfo,
    Error,
    TData
  > => ({
    queryKey: secretDaoPreProposeSingleQueryKeys.proposalModule(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoPreProposeSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposalModule(),
    ...options,
  }),
  dao: <TData = AnyContractInfo>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoPreProposeSingleDaoQuery<TData>): UseQueryOptions<
    AnyContractInfo,
    Error,
    TData
  > => ({
    queryKey: secretDaoPreProposeSingleQueryKeys.dao(chainId, contractAddress),
    queryFn: async () =>
      new SecretDaoPreProposeSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).dao(),
    ...options,
  }),
  config: <TData = Config>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoPreProposeSingleConfigQuery<TData>): UseQueryOptions<
    Config,
    Error,
    TData
  > => ({
    queryKey: secretDaoPreProposeSingleQueryKeys.config(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoPreProposeSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).config(),
    ...options,
  }),
  depositInfo: <TData = DepositInfoResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoPreProposeSingleDepositInfoQuery<TData>): UseQueryOptions<
    DepositInfoResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoPreProposeSingleQueryKeys.depositInfo(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoPreProposeSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).depositInfo({
        proposalId: args.proposalId,
      }),
    ...options,
  }),
  proposalSubmittedHooks: <TData = HooksResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoPreProposeSingleProposalSubmittedHooksQuery<TData>): UseQueryOptions<
    HooksResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoPreProposeSingleQueryKeys.proposalSubmittedHooks(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoPreProposeSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposalSubmittedHooks(),
    ...options,
  }),
  queryExtension: <TData = Binary>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoPreProposeSingleQueryExtensionQuery<TData>): UseQueryOptions<
    Binary,
    Error,
    TData
  > => ({
    queryKey: secretDaoPreProposeSingleQueryKeys.queryExtension(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoPreProposeSingleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).queryExtension({
        msg: args.msg,
      }),
    ...options,
  }),
  info: contractQueries.info,
}
export interface SecretDaoPreProposeSingleReactQuery<
  TResponse,
  TData = TResponse
> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface SecretDaoPreProposeSingleQueryExtensionQuery<TData>
  extends SecretDaoPreProposeSingleReactQuery<Binary, TData> {
  args: {
    msg: Empty
  }
}
export interface SecretDaoPreProposeSingleProposalSubmittedHooksQuery<TData>
  extends SecretDaoPreProposeSingleReactQuery<HooksResponse, TData> {}
export interface SecretDaoPreProposeSingleDepositInfoQuery<TData>
  extends SecretDaoPreProposeSingleReactQuery<DepositInfoResponse, TData> {
  args: {
    proposalId: number
  }
}
export interface SecretDaoPreProposeSingleConfigQuery<TData>
  extends SecretDaoPreProposeSingleReactQuery<Config, TData> {}
export interface SecretDaoPreProposeSingleDaoQuery<TData>
  extends SecretDaoPreProposeSingleReactQuery<AnyContractInfo, TData> {}
export interface SecretDaoPreProposeSingleProposalModuleQuery<TData>
  extends SecretDaoPreProposeSingleReactQuery<AnyContractInfo, TData> {}
