/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { QueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  AdminResponse,
  HooksResponse,
  ListMembersResponse,
  MemberResponse,
  TotalWeightResponse,
} from '@dao-dao/types/contracts/Cw4Group'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { Cw4GroupQueryClient } from '../../../contracts/Cw4Group'
import { indexerQueries } from '../indexer'

export const cw4GroupQueryKeys = {
  contract: [
    {
      contract: 'cw4Group',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...cw4GroupQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  admin: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'admin',
        args,
      },
    ] as const,
  totalWeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_weight',
        args,
      },
    ] as const,
  listMembers: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_members',
        args,
      },
    ] as const,
  member: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'member',
        args,
      },
    ] as const,
  hooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw4GroupQueryKeys.address(chainId, contractAddress)[0],
        method: 'hooks',
        args,
      },
    ] as const,
}
export const cw4GroupQueries = {
  admin: <TData = AdminResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw4GroupAdminQuery<TData>
  ): UseQueryOptions<AdminResponse, Error, TData> => ({
    queryKey: cw4GroupQueryKeys.admin(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          admin: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw4Group/admin',
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).admin()
    },
    ...options,
  }),
  totalWeight: <TData = TotalWeightResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw4GroupTotalWeightQuery<TData>
  ): UseQueryOptions<TotalWeightResponse, Error, TData> => ({
    queryKey: cw4GroupQueryKeys.totalWeight(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          weight: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw4Group/totalWeight',
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalWeight()
    },
    ...options,
  }),
  listMembers: <TData = ListMembersResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, args, options }: Cw4GroupListMembersQuery<TData>
  ): UseQueryOptions<ListMembersResponse, Error, TData> => ({
    queryKey: cw4GroupQueryKeys.listMembers(chainId, contractAddress, args),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw4Group/listMembers',
            args,
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listMembers({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  member: <TData = MemberResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, args, options }: Cw4GroupMemberQuery<TData>
  ): UseQueryOptions<MemberResponse, Error, TData> => ({
    queryKey: cw4GroupQueryKeys.member(chainId, contractAddress, args),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          weight: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw4Group/member',
              args: {
                address: args.addr,
                height: args.atHeight,
              },
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).member({
        addr: args.addr,
        atHeight: args.atHeight,
      })
    },
    ...options,
  }),
  hooks: <TData = HooksResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw4GroupHooksQuery<TData>
  ): UseQueryOptions<HooksResponse, Error, TData> => ({
    queryKey: cw4GroupQueryKeys.hooks(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw4Group/hooks',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw4GroupQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).hooks()
    },
    ...options,
  }),
}
export interface Cw4GroupReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface Cw4GroupHooksQuery<TData>
  extends Cw4GroupReactQuery<HooksResponse, TData> {}
export interface Cw4GroupMemberQuery<TData>
  extends Cw4GroupReactQuery<MemberResponse, TData> {
  args: {
    addr: string
    atHeight?: number
  }
}
export interface Cw4GroupListMembersQuery<TData>
  extends Cw4GroupReactQuery<ListMembersResponse, TData> {
  args: {
    limit?: number
    startAfter?: string
  }
}
export interface Cw4GroupTotalWeightQuery<TData>
  extends Cw4GroupReactQuery<TotalWeightResponse, TData> {}
export interface Cw4GroupAdminQuery<TData>
  extends Cw4GroupReactQuery<AdminResponse, TData> {}
