/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'

import {
  AdminResponse,
  Auth,
  HookItem,
  HooksResponse,
  Member,
  MemberListResponse,
  MemberResponse,
  TotalWeightResponse,
} from '@dao-dao/types/contracts/SecretCw4Group'
import { CHAIN_GAS_MULTIPLIER } from '@dao-dao/utils'

export interface SecretCw4GroupReadOnlyInterface {
  contractAddress: string
  admin: () => Promise<AdminResponse>
  totalWeight: ({
    atHeight,
  }: {
    atHeight?: number
  }) => Promise<TotalWeightResponse>
  listMembers: ({
    limit,
    startAfter,
  }: {
    limit?: number
    startAfter?: string
  }) => Promise<MemberListResponse>
  member: ({
    atHeight,
    auth,
  }: {
    atHeight?: number
    auth: Auth
  }) => Promise<MemberResponse>
  hooks: () => Promise<HooksResponse>
}
export class SecretCw4GroupQueryClient
  implements SecretCw4GroupReadOnlyInterface
{
  client: CosmWasmClient
  contractAddress: string
  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.admin = this.admin.bind(this)
    this.totalWeight = this.totalWeight.bind(this)
    this.listMembers = this.listMembers.bind(this)
    this.member = this.member.bind(this)
    this.hooks = this.hooks.bind(this)
  }
  admin = async (): Promise<AdminResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      admin: {},
    })
  }
  totalWeight = async ({
    atHeight,
  }: {
    atHeight?: number
  }): Promise<TotalWeightResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      total_weight: {
        at_height: atHeight,
      },
    })
  }
  listMembers = async ({
    limit,
    startAfter,
  }: {
    limit?: number
    startAfter?: string
  }): Promise<MemberListResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_members: {
        limit,
        start_after: startAfter,
      },
    })
  }
  member = async ({
    atHeight,
    auth,
  }: {
    atHeight?: number
    auth: Auth
  }): Promise<MemberResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      member: {
        at_height: atHeight,
        auth,
      },
    })
  }
  hooks = async (): Promise<HooksResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      hooks: {},
    })
  }
}
export interface SecretCw4GroupInterface
  extends SecretCw4GroupReadOnlyInterface {
  contractAddress: string
  sender: string
  updateAdmin: (
    {
      admin,
    }: {
      admin?: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  updateMembers: (
    {
      add,
      remove,
    }: {
      add: Member[]
      remove: string[]
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  addHook: (
    {
      hook,
    }: {
      hook: HookItem
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  removeHook: (
    {
      hook,
    }: {
      hook: HookItem
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
}
export class SecretCw4GroupClient
  extends SecretCw4GroupQueryClient
  implements SecretCw4GroupInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string
  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.updateAdmin = this.updateAdmin.bind(this)
    this.updateMembers = this.updateMembers.bind(this)
    this.addHook = this.addHook.bind(this)
    this.removeHook = this.removeHook.bind(this)
  }
  updateAdmin = async (
    {
      admin,
    }: {
      admin?: string
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_admin: {
          admin,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  updateMembers = async (
    {
      add,
      remove,
    }: {
      add: Member[]
      remove: string[]
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_members: {
          add,
          remove,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  addHook = async (
    {
      hook,
    }: {
      hook: HookItem
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        add_hook: {
          hook,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  removeHook = async (
    {
      hook,
    }: {
      hook: HookItem
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        remove_hook: {
          hook,
        },
      },
      fee,
      memo,
      _funds
    )
  }
}
