/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'

import { Addr } from '@dao-dao/types'
import {
  Binary,
  Coin,
  CosmosMsgForEmpty,
  Expiration,
  ValenceServices,
} from '@dao-dao/types/contracts/ValenceAccount'
import { CHAIN_GAS_MULTIPLIER } from '@dao-dao/utils'

export interface ValenceAccountReadOnlyInterface {
  contractAddress: string
  getAdmin: () => Promise<Addr>
}
export class ValenceAccountQueryClient
  implements ValenceAccountReadOnlyInterface
{
  client: CosmWasmClient
  contractAddress: string
  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.getAdmin = this.getAdmin.bind(this)
  }
  getAdmin = async (): Promise<Addr> => {
    return this.client.queryContractSmart(this.contractAddress, 'get_admin')
  }
}
export interface ValenceAccountInterface
  extends ValenceAccountReadOnlyInterface {
  contractAddress: string
  sender: string
  registerToService: (
    {
      data,
      serviceName,
    }: {
      data?: Binary
      serviceName: ValenceServices
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  deregisterFromService: (
    {
      serviceName,
    }: {
      serviceName: ValenceServices
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  updateService: (
    {
      data,
      serviceName,
    }: {
      data: Binary
      serviceName: ValenceServices
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  pauseService: (
    {
      reason,
      serviceName,
    }: {
      reason?: string
      serviceName: ValenceServices
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  resumeService: (
    {
      serviceName,
    }: {
      serviceName: ValenceServices
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  sendFundsByService: (
    {
      atomic,
      msgs,
    }: {
      atomic: boolean
      msgs: CosmosMsgForEmpty[]
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  executeByService: (
    {
      atomic,
      msgs,
    }: {
      atomic: boolean
      msgs: CosmosMsgForEmpty[]
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  executeByAdmin: (
    {
      msgs,
    }: {
      msgs: CosmosMsgForEmpty[]
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  startAdminChange: (
    {
      addr,
      expiration,
    }: {
      addr: string
      expiration: Expiration
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  cancelAdminChange: (
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  approveAdminChange: (
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
}
export class ValenceAccountClient
  extends ValenceAccountQueryClient
  implements ValenceAccountInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string
  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.registerToService = this.registerToService.bind(this)
    this.deregisterFromService = this.deregisterFromService.bind(this)
    this.updateService = this.updateService.bind(this)
    this.pauseService = this.pauseService.bind(this)
    this.resumeService = this.resumeService.bind(this)
    this.sendFundsByService = this.sendFundsByService.bind(this)
    this.executeByService = this.executeByService.bind(this)
    this.executeByAdmin = this.executeByAdmin.bind(this)
    this.startAdminChange = this.startAdminChange.bind(this)
    this.cancelAdminChange = this.cancelAdminChange.bind(this)
    this.approveAdminChange = this.approveAdminChange.bind(this)
  }
  registerToService = async (
    {
      data,
      serviceName,
    }: {
      data?: Binary
      serviceName: ValenceServices
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        register_to_service: {
          data,
          service_name: serviceName,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  deregisterFromService = async (
    {
      serviceName,
    }: {
      serviceName: ValenceServices
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        deregister_from_service: {
          service_name: serviceName,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  updateService = async (
    {
      data,
      serviceName,
    }: {
      data: Binary
      serviceName: ValenceServices
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_service: {
          data,
          service_name: serviceName,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  pauseService = async (
    {
      reason,
      serviceName,
    }: {
      reason?: string
      serviceName: ValenceServices
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        pause_service: {
          reason,
          service_name: serviceName,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  resumeService = async (
    {
      serviceName,
    }: {
      serviceName: ValenceServices
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        resume_service: {
          service_name: serviceName,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  sendFundsByService = async (
    {
      atomic,
      msgs,
    }: {
      atomic: boolean
      msgs: CosmosMsgForEmpty[]
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        send_funds_by_service: {
          atomic,
          msgs,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  executeByService = async (
    {
      atomic,
      msgs,
    }: {
      atomic: boolean
      msgs: CosmosMsgForEmpty[]
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        execute_by_service: {
          atomic,
          msgs,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  executeByAdmin = async (
    {
      msgs,
    }: {
      msgs: CosmosMsgForEmpty[]
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        execute_by_admin: {
          msgs,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  startAdminChange = async (
    {
      addr,
      expiration,
    }: {
      addr: string
      expiration: Expiration
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        start_admin_change: {
          addr,
          expiration,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  cancelAdminChange = async (
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        cancel_admin_change: {},
      },
      fee,
      memo,
      _funds
    )
  }
  approveAdminChange = async (
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        approve_admin_change: {},
      },
      fee,
      memo,
      _funds
    )
  }
}
