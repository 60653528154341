/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  Addr,
  ArrayOfQueryPoolInfoResponse,
  ArrayOfRewardInfoResponse,
  ConfigResponse,
  LockInfosResponse,
  PoolInfoResponse,
  RewardInfoResponse,
  RewardsPerSecResponse,
  StakedBalanceAtHeightResponse,
  TotalStakedAtHeightResponse,
} from '@dao-dao/types/contracts/OraichainCw20Staking'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { OraichainCw20StakingQueryClient } from '../../../contracts/OraichainCw20Staking'

export const oraichainCw20StakingQueryKeys = {
  contract: [
    {
      contract: 'oraichainCw20Staking',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  config: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'config',
        args,
      },
    ] as const,
  poolInfo: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'pool_info',
        args,
      },
    ] as const,
  rewardsPerSec: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'rewards_per_sec',
        args,
      },
    ] as const,
  rewardInfo: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'reward_info',
        args,
      },
    ] as const,
  rewardInfos: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'reward_infos',
        args,
      },
    ] as const,
  getPoolsInformation: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_pools_information',
        args,
      },
    ] as const,
  lockInfos: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'lock_infos',
        args,
      },
    ] as const,
  stakedBalanceAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'staked_balance_at_height',
        args,
      },
    ] as const,
  totalStakedAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...oraichainCw20StakingQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_staked_at_height',
        args,
      },
    ] as const,
}
export const oraichainCw20StakingQueries = {
  config: <TData = ConfigResponse>({
    chainId,
    contractAddress,
    options,
  }: OraichainCw20StakingConfigQuery<TData>): UseQueryOptions<
    ConfigResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.config(chainId, contractAddress),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).config()
    },
    ...options,
  }),
  poolInfo: <TData = PoolInfoResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: OraichainCw20StakingPoolInfoQuery<TData>): UseQueryOptions<
    PoolInfoResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.poolInfo(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).poolInfo({
        stakingToken: args.stakingToken,
      })
    },
    ...options,
  }),
  rewardsPerSec: <TData = RewardsPerSecResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: OraichainCw20StakingRewardsPerSecQuery<TData>): UseQueryOptions<
    RewardsPerSecResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.rewardsPerSec(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).rewardsPerSec({
        stakingToken: args.stakingToken,
      })
    },
    ...options,
  }),
  rewardInfo: <TData = RewardInfoResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: OraichainCw20StakingRewardInfoQuery<TData>): UseQueryOptions<
    RewardInfoResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.rewardInfo(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).rewardInfo({
        stakerAddr: args.stakerAddr,
        stakingToken: args.stakingToken,
      })
    },
    ...options,
  }),
  rewardInfos: <TData = ArrayOfRewardInfoResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: OraichainCw20StakingRewardInfosQuery<TData>): UseQueryOptions<
    ArrayOfRewardInfoResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.rewardInfos(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).rewardInfos({
        limit: args.limit,
        order: args.order,
        stakingToken: args.stakingToken,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  getPoolsInformation: <TData = ArrayOfQueryPoolInfoResponse>({
    chainId,
    contractAddress,
    options,
  }: OraichainCw20StakingGetPoolsInformationQuery<TData>): UseQueryOptions<
    ArrayOfQueryPoolInfoResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.getPoolsInformation(
      chainId,
      contractAddress
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getPoolsInformation()
    },
    ...options,
  }),
  lockInfos: <TData = LockInfosResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: OraichainCw20StakingLockInfosQuery<TData>): UseQueryOptions<
    LockInfosResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.lockInfos(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).lockInfos({
        limit: args.limit,
        order: args.order,
        stakerAddr: args.stakerAddr,
        stakingToken: args.stakingToken,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  stakedBalanceAtHeight: <TData = StakedBalanceAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: OraichainCw20StakingStakedBalanceAtHeightQuery<TData>): UseQueryOptions<
    StakedBalanceAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.stakedBalanceAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).stakedBalanceAtHeight({
        address: args.address,
        assetKey: args.assetKey,
        height: args.height,
      })
    },
    ...options,
  }),
  totalStakedAtHeight: <TData = TotalStakedAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: OraichainCw20StakingTotalStakedAtHeightQuery<TData>): UseQueryOptions<
    TotalStakedAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: oraichainCw20StakingQueryKeys.totalStakedAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new OraichainCw20StakingQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalStakedAtHeight({
        assetKey: args.assetKey,
        height: args.height,
      })
    },
    ...options,
  }),
}
export interface OraichainCw20StakingReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface OraichainCw20StakingTotalStakedAtHeightQuery<TData>
  extends OraichainCw20StakingReactQuery<TotalStakedAtHeightResponse, TData> {
  args: {
    assetKey: Addr
    height?: number
  }
}
export interface OraichainCw20StakingStakedBalanceAtHeightQuery<TData>
  extends OraichainCw20StakingReactQuery<StakedBalanceAtHeightResponse, TData> {
  args: {
    address: string
    assetKey: Addr
    height?: number
  }
}
export interface OraichainCw20StakingLockInfosQuery<TData>
  extends OraichainCw20StakingReactQuery<LockInfosResponse, TData> {
  args: {
    limit?: number
    order?: number
    stakerAddr: Addr
    stakingToken: Addr
    startAfter?: number
  }
}
export interface OraichainCw20StakingGetPoolsInformationQuery<TData>
  extends OraichainCw20StakingReactQuery<ArrayOfQueryPoolInfoResponse, TData> {}
export interface OraichainCw20StakingRewardInfosQuery<TData>
  extends OraichainCw20StakingReactQuery<ArrayOfRewardInfoResponse, TData> {
  args: {
    limit?: number
    order?: number
    stakingToken: Addr
    startAfter?: Addr
  }
}
export interface OraichainCw20StakingRewardInfoQuery<TData>
  extends OraichainCw20StakingReactQuery<RewardInfoResponse, TData> {
  args: {
    stakerAddr: Addr
    stakingToken?: Addr
  }
}
export interface OraichainCw20StakingRewardsPerSecQuery<TData>
  extends OraichainCw20StakingReactQuery<RewardsPerSecResponse, TData> {
  args: {
    stakingToken: Addr
  }
}
export interface OraichainCw20StakingPoolInfoQuery<TData>
  extends OraichainCw20StakingReactQuery<PoolInfoResponse, TData> {
  args: {
    stakingToken: Addr
  }
}
export interface OraichainCw20StakingConfigQuery<TData>
  extends OraichainCw20StakingReactQuery<ConfigResponse, TData> {}
