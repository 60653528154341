import { useTranslation } from 'react-i18next'

import { useDaoNavHelpers } from '../../hooks'
import { ButtonLink } from '../buttons'
import { ErrorPage } from './ErrorPage'

export const ErrorPage404 = () => {
  const { t } = useTranslation()

  // If on a DAO page, return to DAO home instead of site home.
  const {
    getDaoPath,
    router: { asPath },
  } = useDaoNavHelpers()
  // Get DAO address from path if exists.
  const coreAddress = asPath.startsWith(getDaoPath(''))
    ? asPath.replace(getDaoPath(''), '').split('/')[0]
    : null

  return (
    <ErrorPage title={t('title.404')}>
      <ButtonLink
        href={coreAddress ? getDaoPath(coreAddress) : '/'}
        variant="secondary"
      >
        {t('button.returnHome')}
      </ButtonLink>
    </ErrorPage>
  )
}
