/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  Addr,
  Auth,
  Config,
  HooksResponse,
  InfoResponse,
  ProposalCreationPolicy,
  ProposalListResponse,
  ProposalResponse,
  Uint64,
  VoteListResponse,
  VoteResponse,
} from '@dao-dao/types/contracts/SecretDaoProposalMultiple'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { SecretDaoProposalMultipleQueryClient } from '../../../contracts/SecretDaoProposalMultiple'

export const secretDaoProposalMultipleQueryKeys = {
  contract: [
    {
      contract: 'secretDaoProposalMultiple',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  config: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'config',
        args,
      },
    ] as const,
  proposal: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal',
        args,
      },
    ] as const,
  listProposals: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'list_proposals',
        args,
      },
    ] as const,
  reverseProposals: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'reverse_proposals',
        args,
      },
    ] as const,
  getVote: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'get_vote',
        args,
      },
    ] as const,
  listVotes: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'list_votes',
        args,
      },
    ] as const,
  proposalCount: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal_count',
        args,
      },
    ] as const,
  proposalCreationPolicy: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal_creation_policy',
        args,
      },
    ] as const,
  proposalHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'proposal_hooks',
        args,
      },
    ] as const,
  voteHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'vote_hooks',
        args,
      },
    ] as const,
  dao: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'dao',
        args,
      },
    ] as const,
  info: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'info',
        args,
      },
    ] as const,
  nextProposalId: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoProposalMultipleQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'next_proposal_id',
        args,
      },
    ] as const,
}
export const secretDaoProposalMultipleQueries = {
  config: <TData = Config>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleConfigQuery<TData>): UseQueryOptions<
    Config,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.config(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).config(),
    ...options,
  }),
  proposal: <TData = ProposalResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoProposalMultipleProposalQuery<TData>): UseQueryOptions<
    ProposalResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.proposal(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposal({
        proposalId: args.proposalId,
      }),
    ...options,
  }),
  listProposals: <TData = ProposalListResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoProposalMultipleListProposalsQuery<TData>): UseQueryOptions<
    ProposalListResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.listProposals(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listProposals({
        limit: args.limit,
        startAfter: args.startAfter,
      }),
    ...options,
  }),
  reverseProposals: <TData = ProposalListResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoProposalMultipleReverseProposalsQuery<TData>): UseQueryOptions<
    ProposalListResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.reverseProposals(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).reverseProposals({
        limit: args.limit,
        startBefore: args.startBefore,
      }),
    ...options,
  }),
  getVote: <TData = VoteResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoProposalMultipleGetVoteQuery<TData>): UseQueryOptions<
    VoteResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.getVote(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getVote({
        auth: args.auth,
        proposalId: args.proposalId,
      }),
    ...options,
  }),
  listVotes: <TData = VoteListResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoProposalMultipleListVotesQuery<TData>): UseQueryOptions<
    VoteListResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.listVotes(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listVotes({
        limit: args.limit,
        proposalId: args.proposalId,
        startAfter: args.startAfter,
      }),
    ...options,
  }),
  proposalCount: <TData = number>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleProposalCountQuery<TData>): UseQueryOptions<
    number,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.proposalCount(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposalCount(),
    ...options,
  }),
  proposalCreationPolicy: <TData = ProposalCreationPolicy>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleProposalCreationPolicyQuery<TData>): UseQueryOptions<
    ProposalCreationPolicy,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.proposalCreationPolicy(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposalCreationPolicy(),
    ...options,
  }),
  proposalHooks: <TData = HooksResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleProposalHooksQuery<TData>): UseQueryOptions<
    HooksResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.proposalHooks(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).proposalHooks(),
    ...options,
  }),
  voteHooks: <TData = HooksResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleVoteHooksQuery<TData>): UseQueryOptions<
    HooksResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.voteHooks(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).voteHooks(),
    ...options,
  }),
  dao: <TData = Addr>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleDaoQuery<TData>): UseQueryOptions<
    Addr,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.dao(chainId, contractAddress),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).dao(),
    ...options,
  }),
  info: <TData = InfoResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleInfoQuery<TData>): UseQueryOptions<
    InfoResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.info(chainId, contractAddress),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).info(),
    ...options,
  }),
  nextProposalId: <TData = Uint64>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoProposalMultipleNextProposalIdQuery<TData>): UseQueryOptions<
    Uint64,
    Error,
    TData
  > => ({
    queryKey: secretDaoProposalMultipleQueryKeys.nextProposalId(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoProposalMultipleQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).nextProposalId(),
    ...options,
  }),
}
export interface SecretDaoProposalMultipleReactQuery<
  TResponse,
  TData = TResponse
> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface SecretDaoProposalMultipleNextProposalIdQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<Uint64, TData> {}
export interface SecretDaoProposalMultipleInfoQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<InfoResponse, TData> {}
export interface SecretDaoProposalMultipleDaoQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<Addr, TData> {}
export interface SecretDaoProposalMultipleVoteHooksQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<HooksResponse, TData> {}
export interface SecretDaoProposalMultipleProposalHooksQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<HooksResponse, TData> {}
export interface SecretDaoProposalMultipleProposalCreationPolicyQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<ProposalCreationPolicy, TData> {}
export interface SecretDaoProposalMultipleProposalCountQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<number, TData> {}
export interface SecretDaoProposalMultipleListVotesQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<VoteListResponse, TData> {
  args: {
    limit?: number
    proposalId: number
    startAfter?: string
  }
}
export interface SecretDaoProposalMultipleGetVoteQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<VoteResponse, TData> {
  args: {
    auth: Auth
    proposalId: number
  }
}
export interface SecretDaoProposalMultipleReverseProposalsQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<ProposalListResponse, TData> {
  args: {
    limit?: number
    startBefore?: number
  }
}
export interface SecretDaoProposalMultipleListProposalsQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<ProposalListResponse, TData> {
  args: {
    limit?: number
    startAfter?: number
  }
}
export interface SecretDaoProposalMultipleProposalQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<ProposalResponse, TData> {
  args: {
    proposalId: number
  }
}
export interface SecretDaoProposalMultipleConfigQuery<TData>
  extends SecretDaoProposalMultipleReactQuery<Config, TData> {}
