/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'

import { Binary, SenderInfo } from '@dao-dao/types/contracts/PolytoneVoice'

export interface PolytoneVoiceReadOnlyInterface {
  contractAddress: string
  senderInfoForProxy: ({ proxy }: { proxy: string }) => Promise<SenderInfo>
}
export class PolytoneVoiceQueryClient
  implements PolytoneVoiceReadOnlyInterface
{
  client: CosmWasmClient
  contractAddress: string
  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.senderInfoForProxy = this.senderInfoForProxy.bind(this)
  }
  senderInfoForProxy = async ({
    proxy,
  }: {
    proxy: string
  }): Promise<SenderInfo> => {
    return this.client.queryContractSmart(this.contractAddress, {
      sender_info_for_proxy: {
        proxy,
      },
    })
  }
}
export interface PolytoneVoiceInterface extends PolytoneVoiceReadOnlyInterface {
  contractAddress: string
  sender: string
  rx: (
    {
      connectionId,
      counterpartyPort,
      data,
    }: {
      connectionId: string
      counterpartyPort: string
      data: Binary
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
}
export class PolytoneVoiceClient
  extends PolytoneVoiceQueryClient
  implements PolytoneVoiceInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string
  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.rx = this.rx.bind(this)
  }
  rx = async (
    {
      connectionId,
      counterpartyPort,
      data,
    }: {
      connectionId: string
      counterpartyPort: string
      data: Binary
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        rx: {
          connection_id: connectionId,
          counterparty_port: counterpartyPort,
          data,
        },
      },
      fee,
      memo,
      _funds
    )
  }
}
