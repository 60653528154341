/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  ClaimsResponse,
  Config,
  GetHooksResponse,
  ListStakersResponse,
  OwnershipForAddr,
  StakedBalanceAtHeightResponse,
  StakedValueResponse,
  TotalStakedAtHeightResponse,
  TotalValueResponse,
} from '@dao-dao/types/contracts/Snip20Stake'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { Snip20StakeQueryClient } from '../../../contracts/Snip20Stake'

export const snip20StakeQueryKeys = {
  contract: [
    {
      contract: 'snip20Stake',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...snip20StakeQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  stakedBalanceAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'staked_balance_at_height',
        args,
      },
    ] as const,
  totalStakedAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_staked_at_height',
        args,
      },
    ] as const,
  stakedValue: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'staked_value',
        args,
      },
    ] as const,
  totalValue: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_value',
        args,
      },
    ] as const,
  getConfig: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_config',
        args,
      },
    ] as const,
  claims: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'claims',
        args,
      },
    ] as const,
  getHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_hooks',
        args,
      },
    ] as const,
  listStakers: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_stakers',
        args,
      },
    ] as const,
  ownership: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...snip20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'ownership',
        args,
      },
    ] as const,
}
export const snip20StakeQueries = {
  stakedBalanceAtHeight: <TData = StakedBalanceAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: Snip20StakeStakedBalanceAtHeightQuery<TData>): UseQueryOptions<
    StakedBalanceAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.stakedBalanceAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).stakedBalanceAtHeight({
        address: args.address,
        height: args.height,
      })
    },
    ...options,
  }),
  totalStakedAtHeight: <TData = TotalStakedAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: Snip20StakeTotalStakedAtHeightQuery<TData>): UseQueryOptions<
    TotalStakedAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.totalStakedAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalStakedAtHeight({
        height: args.height,
      })
    },
    ...options,
  }),
  stakedValue: <TData = StakedValueResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: Snip20StakeStakedValueQuery<TData>): UseQueryOptions<
    StakedValueResponse,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.stakedValue(chainId, contractAddress, args),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).stakedValue({
        address: args.address,
      })
    },
    ...options,
  }),
  totalValue: <TData = TotalValueResponse>({
    chainId,
    contractAddress,
    options,
  }: Snip20StakeTotalValueQuery<TData>): UseQueryOptions<
    TotalValueResponse,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.totalValue(chainId, contractAddress),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalValue()
    },
    ...options,
  }),
  getConfig: <TData = Config>({
    chainId,
    contractAddress,
    options,
  }: Snip20StakeGetConfigQuery<TData>): UseQueryOptions<
    Config,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.getConfig(chainId, contractAddress),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getConfig()
    },
    ...options,
  }),
  claims: <TData = ClaimsResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: Snip20StakeClaimsQuery<TData>): UseQueryOptions<
    ClaimsResponse,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.claims(chainId, contractAddress, args),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).claims({
        address: args.address,
      })
    },
    ...options,
  }),
  getHooks: <TData = GetHooksResponse>({
    chainId,
    contractAddress,
    options,
  }: Snip20StakeGetHooksQuery<TData>): UseQueryOptions<
    GetHooksResponse,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.getHooks(chainId, contractAddress),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getHooks()
    },
    ...options,
  }),
  listStakers: <TData = ListStakersResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: Snip20StakeListStakersQuery<TData>): UseQueryOptions<
    ListStakersResponse,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.listStakers(chainId, contractAddress, args),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listStakers({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  ownership: <TData = OwnershipForAddr>({
    chainId,
    contractAddress,
    options,
  }: Snip20StakeOwnershipQuery<TData>): UseQueryOptions<
    OwnershipForAddr,
    Error,
    TData
  > => ({
    queryKey: snip20StakeQueryKeys.ownership(chainId, contractAddress),
    queryFn: async () => {
      return new Snip20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).ownership()
    },
    ...options,
  }),
}
export interface Snip20StakeReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface Snip20StakeOwnershipQuery<TData>
  extends Snip20StakeReactQuery<OwnershipForAddr, TData> {}
export interface Snip20StakeListStakersQuery<TData>
  extends Snip20StakeReactQuery<ListStakersResponse, TData> {
  args: {
    limit?: number
    startAfter?: string
  }
}
export interface Snip20StakeGetHooksQuery<TData>
  extends Snip20StakeReactQuery<GetHooksResponse, TData> {}
export interface Snip20StakeClaimsQuery<TData>
  extends Snip20StakeReactQuery<ClaimsResponse, TData> {
  args: {
    address: string
  }
}
export interface Snip20StakeGetConfigQuery<TData>
  extends Snip20StakeReactQuery<Config, TData> {}
export interface Snip20StakeTotalValueQuery<TData>
  extends Snip20StakeReactQuery<TotalValueResponse, TData> {}
export interface Snip20StakeStakedValueQuery<TData>
  extends Snip20StakeReactQuery<StakedValueResponse, TData> {
  args: {
    address: string
  }
}
export interface Snip20StakeTotalStakedAtHeightQuery<TData>
  extends Snip20StakeReactQuery<TotalStakedAtHeightResponse, TData> {
  args: {
    height?: number
  }
}
export interface Snip20StakeStakedBalanceAtHeightQuery<TData>
  extends Snip20StakeReactQuery<StakedBalanceAtHeightResponse, TData> {
  args: {
    address: string
    height?: number
  }
}
