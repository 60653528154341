/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import { Addr } from '@dao-dao/types/contracts/ValenceAccount'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { ValenceAccountQueryClient } from '../../../contracts/ValenceAccount'

export const valenceAccountQueryKeys = {
  contract: [
    {
      contract: 'valenceAccount',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...valenceAccountQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  getAdmin: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...valenceAccountQueryKeys.address(chainId, contractAddress)[0],
        method: 'getAdmin',
        args,
      },
    ] as const,
}
export const valenceAccountQueries = {
  getAdmin: <TData = Addr>({
    chainId,
    contractAddress,
    options,
  }: ValenceAccountGetAdminQuery<TData>): UseQueryOptions<
    Addr,
    Error,
    TData
  > => ({
    queryKey: valenceAccountQueryKeys.getAdmin(chainId, contractAddress),
    queryFn: async () =>
      new ValenceAccountQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getAdmin(),
    ...options,
  }),
}
export interface ValenceAccountReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface ValenceAccountGetAdminQuery<TData>
  extends ValenceAccountReactQuery<Addr, TData> {}
